@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HeadlinesFont';
  src: url('../public/fonts/Headlines_font_ALL_CAPS.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SubheadingFont';
  src: url('../public//fonts/Subheading_font.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

*, *::before, *::after {
  box-sizing: border-box;
}
/* 2. Remove default margin */
* {
  margin: 0;
}
body {
  /* 3. Add accessible line-height */
  line-height: 1.5;
  /* 4. Improve text rendering */
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  font-family: "HeadlinesFont", sans-serif;
}
/* 5. Improve media defaults */
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/* 6. Inherit fonts for form controls */
input, button, textarea, select {
  font: inherit;
}
/* 7. Avoid text overflows */
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/* 8. Improve line wrapping */
p {
  text-wrap: pretty;
}
h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

.reveal-mask {
  clip-path: polygon(0 0, 110% 0, 110% 110%, 0% 110%);
}
